<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>로그아웃 하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closeLogout')">아니오</p>
                <p id="color-text" @click="spaceDisConnect()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { leaveChannel } from '@/assets/js/webcast';
import VueCookies from 'vue-cookies';
import axios from '@/service/axios';
import Hls from 'hls.js';
import { controller } from '@/service/axiosCancel';
export default {
  name: 'logout',
  methods: {
    spaceDisConnect () {
      var userId = VueCookies.get('userId');
      var spaceId = VueCookies.get('spaceId');
      var sessionId = localStorage.getItem('sessionId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      if (spaceId != null) {
        axios
          .post('/api/spaceSession/spaceDisConnection', { userId, spaceId, sessionId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              this.logout();
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.logout();
      }
    },
    async logout () {
      // 채널ID 있을때 채널이탈 요청
      if (this.$store.state.channelId !== '') await leaveChannel();
      if (Hls.isSupported()) if (this.$store.hls !== undefined) this.$store.hls.destroy();
      if (this.$store.state.hls) {
        this.$store.state.hls.destroy();
      }
      const accessToken = VueCookies.get('aToken');
      const refreshToken = VueCookies.get('rToken');
      const comeFrom = VueCookies.get('comeFrom');

      axios
        .post('/api/auth/logout', { accessToken, refreshToken, comeFrom })
        .then(res => {
          if (res.data.resultCd === '0000') {
            // comeFrom값 가져오기
            const comeFrom = VueCookies.get('comeFrom');
            // 카카오 / 네이버 토큰 삭제
            /* if (comeFrom === 'kakao' || comeFrom === 'naver') {
              this.deleteSocialToken(comeFrom);
            } */
            // 로그아웃 시 쿠키 데이터 삭제
            VueCookies.keys().forEach(cookie => {
              if (cookie.indexOf('common_notice') < 0) {
                this.$cookies.remove(cookie);
              }
            });
            // comeFrom값 다시 셋팅
            VueCookies.set('comeFrom', comeFrom);
            sessionStorage.removeItem('carCurationSpace');
            this.$store.commit('initData'); // vuex 데이터 초기화

            clearInterval(this.$store.state.musicInfoInterval);

            controller.abort();
            window.location.href = `${window.location.origin}/home`;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
