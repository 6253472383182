<template>
    <div class="changepassword_background">
        <div class="changepassword_modal">
            <img
                class="changepassword_close cursor"
                @click="$emit('close-modal')"
                src="/media/img/profile/icon_close.svg"
                alt="종료"
            />
            <h1 class="changepassword_title">비밀번호 변경</h1>
            <div class="changepassword_input_wrap">
                <input
                    type="password"
                    class="w_100 changepassword_input first"
                    placeholder="현재 비밀번호"
                    v-model="password"
                    @keyup="checkLength"
                />
            </div>

            <div class="changepassword_input_wrap">
                <input
                    type="password"
                    class="w_100 changepassword_input"
                    placeholder="새 비밀번호 (영문, 숫자, 특수문자 포함 8-20자)"
                    v-model="newPassword"
                    @keyup="checkPassword"
                />
            </div>

            <div class="changepassword_input_wrap">
                <input
                    type="password"
                    class="w_100 changepassword_input"
                    placeholder="새 비밀번호"
                    @keyup="matchPassword"
                    v-model="reInputPassword"
                />
            </div>

            <div class="error_message_wrap">
                <p class="error_message">{{ alertMessage }}</p>
            </div>

            <div class="flex_justify_center">
                <button class="changepassword_button" @click="changePassWord" :disabled="isDisabled">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ChangePassWord',
  emits: ['change-pass', 'close-modal'],
  data () {
    return {
      alertMessage: '',
      password: '',
      newPassword: '',
      reInputPassword: ''
    };
  },

  mounted () {},

  methods: {
    closeModal () {},
    changePassWord () {
      const sendPassword = {
        password: btoa(this.password),
        newPass: btoa(this.newPassword)
      };
      this.$emit('change-pass', sendPassword);
    },
    checkLength () {
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage = '';
      } else {
        this.alertMessage = '비밀번호를 입력해주세요.';
      }
    },
    checkPassword () {
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.newPassword)
        ? (this.alertMessage = '')
        : (this.alertMessage = '비밀번호를 규칙에 맞게 입력해주세요.');
      if (this.newPassword.length === 0) {
        this.alertMessage = '';
      }
      if (this.newPassword === this.reInputPassword) {
        this.alertMessage = '';
      }
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage = '';
      }
    },
    matchPassword () {
      if (this.newPassword !== this.reInputPassword) {
        this.alertMessage = '비밀번호가 일치하지않습니다.';
        return false;
      }
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage = '';
      } else {
        this.alertMessage = '비밀번호를 입력해주세요.';
      }
    }
  },
  computed: {
    isDisabled () {
      if (this.password && this.newPassword && this.reInputPassword && this.alertMessage === '') {
        return false;
      }
      return true;
    }
  }
};
</script>
<style scoped src="@/assets/css/profile/changepassword.css"></style>
