<template>
    <div class="single-file-alert alert">
        <loadingBar v-if="isLoading" style="z-index: 9999" />
        <div class="single-file-box alert_box">
            <h3>탈퇴하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closeWithdrawalCheck', 'N')">아니오</p>
                <p id="color-text" @click="getwithdrawal()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import loadingBar from "@/components/modal/Profile/loadingBar.vue";

export default defineComponent({
  name: 'withdrawalCheck',
  emits: ["closeWithdrawalCheck"],
  data () {
    return {
      isLoading: false
    };
  },
  components: {
    loadingBar
  },
  methods: {
    getwithdrawal () {
      this.$emit('closeWithdrawalCheck', 'Y');
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
