<template>
    <div class="pc-profile">
        <h3 class="pc_profile_title">프로필 정보</h3>

        <ul class="pc_profile_form flex_column">
            <li class="flex_column">
                <label class="pc_profile_form_label">아이디</label>

                <!-- 소셜 로그인 -->
                <div class="social_login_wrap" v-if="isSocialLogin">
                    <!-- 네이버 -->
                    <img v-if="getComeFrom === 'naver'" class="social_login_img" src="/media/img/visitor/icon_cmt_naver.svg" />
                    <!-- 카카오 -->
                    <img v-if="getComeFrom === 'kakao'" class="social_login_img" src="/media/img/visitor/icon_cmt_kakao.svg" />

                    <input
                        class="social_login_input"
                        :value="getEmail"
                        type="text"
                        placeholder="이메일 정보"
                        disabled="disabled"
                    />
                </div>
                <!-- 이메일 -->
                <div class="social_login_wrap" v-else>
                    <!-- 이메일 -->
                    <img
                        v-if="getComeFrom === 'email'"
                        class="social_login_img email"
                        src="/media/img/visitor/icon_cmt_email.png"
                    />
                    <!-- 애플 -->
                    <img
                        v-if="getComeFrom === 'apple'"
                        class="social_login_img email"
                        src="/media/img/visitor/icon_cmt_apple.png"
                    />

                    <input
                        class="social_login_input"
                        :class="{ email: getComeFrom === 'email' }"
                        :value="userinfo.email"
                        type="text"
                        placeholder="이메일 정보"
                        disabled="disabled"
                    />
                </div>
            </li>
            <li
                class="flex_column pc_profile_form_item"
                v-if="userinfo.comeFrom === 'email' || userinfo.comeFrom === 'apple'"
            >
                <button class="pc_profile_form_pw_btn" @click="$emit('change-password')">비밀번호 변경</button>
            </li>
            <li class="flex_column pc_profile_form_item">
                <label class="pc_profile_form_label">이름</label>
                <input
                    class="pc_profile_login_input disabled"
                    type="text"
                    :value="userinfo.name"
                    placeholder="이메일 정보"
                    disabled="disabled"
                />
            </li>
            <li class="flex_column pc_profile_form_item">
                <label class="pc_profile_form_label">생년월일</label>
                <input
                    class="pc_profile_login_input disabled"
                    type="text"
                    :value="userinfo.birthDate"
                    placeholder="생년월일"
                    disabled="disabled"
                />
            </li>
            <li class="flex_column pc_profile_form_item">
                <label class="pc_profile_form_label">휴대폰 번호</label>
                <input
                    class="pc_profile_login_input disabled"
                    :value="userinfo.mobileNo"
                    type="text"
                    placeholder="휴대폰 번호"
                    disabled="disabled"
                />
            </li>
        </ul>
        <div class="profile-footer">
            <p class="delete-btn" @click="openWithdrawal">회원탈퇴</p>
        </div>

        <Withdrawal v-if="withdrawalStatus" @closeWithdrawal="withdrawalStatus = false"></Withdrawal>
        <ChangePassPopup v-if="changePassStatus" @closeProfile="closeProfile"></ChangePassPopup>
        <withdrawal-info v-if="isWithDrawalInfo" @close-modal="onCloseModal" />
    </div>
</template>

<script>
import ChangePassPopup from '@/components/modal/Profile/ChangePass.vue';
import Withdrawal from '@/components/modal/Profile/Withdrawal.vue';
import WithdrawalInfo from '@/components/modal/Profile/WithdrawlInfo.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'pcChangePass',
  props: {
    userinfo: {
      type: Object,
      required: false,
      default: () => {
        return {
          birthDate: '',
          comeFrom: '',
          email: '',
          mobileNo: '',
          name: ''
        };
      }
    }
  },
  emits: ['change-password'],
  components: {
    Withdrawal,
    ChangePassPopup,
    'withdrawal-info': WithdrawalInfo
  },

  data () {
    return {
      password: '',
      newPassword: '',
      reInputPassword: '',

      alertMessage_1: false,
      alertMessage_2: false,
      alertMessage_3: false,
      alertMessage_4: false,
      isWithDrawalInfo: false,
      withdrawalStatus: '',
      changePassStatus: false
    };
  },

  methods: {
    onCloseModal () {
      this.isWithDrawalInfo = false;
    },
    checkLength () {
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage_3 = false;
      }
    },

    checkPassword () {
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.newPassword)
        ? (this.alertMessage_1 = false)
        : (this.alertMessage_1 = true);
      if (this.newPassword.length === 0) {
        this.alertMessage_1 = false;
      }

      if (this.newPassword === this.reInputPassword) {
        this.alertMessage_2 = false;
      }

      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage_3 = false;
      }
    },

    matchPassword () {
      this.newPassword === this.reInputPassword ? (this.alertMessage_2 = false) : (this.alertMessage_2 = true);

      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage_3 = false;
      }
    },

    async openWithdrawal () {
      const result = await this.getPaidSpacesInApp(this.$cookies.get('userId'));

      const { data } = result;
      const { result: resultInapp } = data;
      if (resultInapp > 0) {
        this.isWithDrawalInfo = true;
      } else {
        this.$emit('openWithDrawlModal');
      }
    },
    async getPaidSpacesInApp (userId) {
      return await axios.post('/api/product/numberOfPaidSpacesInApp', { userId });
    },

    changePass () {
      var password = btoa(this.password);
      var newPass = btoa(this.newPassword);
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      // 규칙에 걸렸는지
      if (!this.alertMessage_1 && !this.alertMessage_2) {
        // 입력 여부 체크
        if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
          this.alertMessage_3 = false;

          axios
            .post('/api/user/updateUserPass', { password, newPass, userId }, { headers })
            .then(res => {
              if (res.data.result) {
                this.changePassStatus = true;
              } else {
                this.alertMessage_4 = true;
              }
            })
            .catch(e => {
              console.log(e);
            });
        } else {
          this.alertMessage_3 = true;
        }
      }
    },

    closeProfile () {
      this.changePassStatus = false;
      this.$emit('closeProfile');
    },

    closePopup () {
      this.$emit('closeMoPopup');
    }
  },
  computed: {
    getEmail () {
      return this.$cookies.get('email') ? this.$cookies.get('email') : '';
    },
    getComeFrom () {
      return this.$cookies.get('comeFrom') ? this.$cookies.get('comeFrom') : '';
    },
    isSocialLogin () {
      const comeFrom = this.userinfo.comeFrom;
      if (comeFrom === 'kakao' || comeFrom === 'naver') {
        return true;
      }

      return false;
    }
  }
};
</script>

<style scoped>
.pc-profile {
  position: relative;
  width: 100%;
  height: 100%;
}

.pc_profile_title {
  color: rgba(255, 255, 255, 0.87);
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
}

.pc_profile_form {
  margin-top: 16px;
}

.pc_profile_form_item {
  margin-top: 12px;
}

.pc_profile_form_pw_btn {
  width: 100%;
  border-radius: 6px;
  color: var(--white);
  border: 1px solid var(--FFFFFFA3);
  padding: 16px 0;
  line-height: 1;
  font-size: 16px;
}

.pc_profile_form_label {
  color: var(--FFFFFFA3);
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
}

.social_login_wrap {
  position: relative;
}

.pc_profile_login_input {
  width: 100%;
}

.pc_profile_login_input.disabled {
  color: var(--FFFFFF4D);
}

.social_login_input {
  width: 100%;
  padding-left: 9%;
}

.social_login_input.email {
  padding-left: 8%;
}

.social_login_img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 23%;
  left: 2%;
}

.social_login_img.email {
  height: 18px;
  width: 24px;
  top: 33%;
}

.social_login_img.apple {
  border-radius: 50%;
}

.profile_content input[type='password'] {
  width: 100%;
  margin-bottom: 8px;
}

.alert-message {
  color: #ff2d55;
}

.profile-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-btn {
  width: 90px;
  height: 46px;
  background: #ff2d55;
  color: #fff;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s;
}

.delete-btn {
  color: var(--FFFFFFA3);
  font-size: 14px;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}

@media all and (max-width: 500px) {
  .pc-profile {
    display: none;
  }
}
</style>
