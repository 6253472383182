<template>
    <div class="withdrawal_container">
        <div class="withdrawal_modal withdrawal_info">
            <div class="head">
                <h1 class="title">
                    회원탈퇴
                </h1>
                <svg @click="$emit('close-modal')" class="close_btn cursor" width="20" height="20" viewBox="0 0 19 19" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                        fill="white" />
                </svg>
            </div>
            <div class="mo_head">
                <img class="back" src="/media/images/icon_back_24.svg" alt="뒤로가기" @click="$emit('close-modal')">
                <h1 class="title mo_title">
                    회원탈퇴
                </h1>
            </div>
            <div class="withdrawal_body">
                <h1 class="top_title top_title_wrap">
                    어플레이즈 계정 : {{ $store.state.email }}
                </h1>
                <p class="withdrawal_desc1">
                    고객님은 현재 인앱결제 중인 상품이 있습니다. <br>
                    아래의 방법으로 직접 구독 해지를 하신 후 서비스를 탈퇴할 수 있습니다.
                </p>

                <h1 class="top_title top_title2">
                    &lt;구독 해지 방법&gt;
                </h1>
                <ul class="withdrawl_desc2">
                    <li class="withdrawl_desc_item">
                        <span class="withdrawl_desc_item1">Google Play :</span>
                        <span class="withdrawl_desc_item2">PlayStore > 우측상단 아이디 아이콘 선택 > 결제 및 정기결제 > 정기결제 > 어플레이즈 구독 취소 선택</span>
                    </li>
                    <li class="withdrawl_desc_item withdrawl_desc_item_mt">
                        <span class="withdrawl_desc_item3">App Store :</span>
                        <span class="withdrawl_desc_item4">설정 > Apple ID > 구독 > 어플레이즈 선택> 정기구독 취소 선택</span>
                    </li>
                </ul>
                <p class="contact">
                    문의전화 (평일 09:00-18:00) 070-4060-8362 (유료)
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'WithdrawlInfo',
  emits: ['close-modal'],
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {
    confirmWithDrawl () {

    }
  }
};
</script>
<style scoped>
.withdrawal_info {
  width: 500px;
}

.withdrawal_body {
  padding: 25px 24px 10px 24px;
}

.top_title {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 20px;
}

.top_title2 {
  margin-top: 25px;
}

.withdrawal_desc1,
.withdrawl_desc2 {
  color: var(--white);
  font-size: 14px;
  line-height: 22px;
  margin-top: 15px;
}

.withdrawl_desc_item {
  display: flex;
}

.withdrawl_desc_item_mt {
  margin-top: 5px;
}

.withdrawl_desc_item1 {
  width: 18%;
}

.withdrawl_desc_item2 {
  width: 82%;
}

.withdrawl_desc_item3 {
  width: 15%;
}

.withdrawl_desc_item4 {
  width: 85%;
}

.mo_footer_btn {
  display: none;
}

.footer_btn {
  padding: 14px 30px;
  font-weight: 500;
  color: var(--white);
  font-size: 16px;
  line-height: 1;
}

.cancel_btn {
  background-color: rgba(21, 21, 21, 0);
}

.cofirm_btn {
  background-color: var(--secondary);
}

.contact {
  color: var(--white);
  font-size: 14px;
  line-height: 22px;
  margin-top: 60px;
}

/* 반응형 */
@media all and (max-width:500px) {
  .withdrawal_info {
    width: 100%;
  }

  .withdrawl_desc_item1 {
    width: 23%;
  }

  .withdrawl_desc_item2 {
    width: 74%;
  }

  .withdrawl_desc_item3 {
    width: 22%;
  }

  .withdrawl_desc_item4 {
    width: 78%;
    word-break: keep-all;
  }

  .contact {
    margin-top: 0;
    position: absolute;
    bottom: 25px;
  }

  .top_title_wrap {
    background: #2a2a2a;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 12px 16px;
  }
}
</style>
