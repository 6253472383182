<template>
    <div class="mo-profile">
        <div class="profile_header">
            <div class="profile_header-close">
                <img src="/media/images/icon_back_24.svg" alt="" @click="closePopup">
            </div>

            <h2 class="txt-white">프로필</h2>

            <div></div>
        </div>

        <div class="profile_title">
            <p>프로필 정보</p>
        </div>
        <div class="profile_content">
            <div class="profile_content-mail">
                <img src="/media/img/visitor/icon_cmt_kakao.svg" v-if="comeFrom === 'kakao'">
                <img src="/media/img/visitor/icon_cmt_naver.svg" v-else>
                <input type="text" placeholder="이메일 정보" v-model="email" disabled>
            </div>
            <input type="text" placeholder="등록된 전화번호" v-model="mobileNo" disabled>

            <p class="delete-btn" @click="openWithdrawal">회원탈퇴</p>
        </div>
        <WithdrawalProcess v-if="isWithDrawal" @closeWithDrawlModal="onCloseWithDrawlModal"
                           @successWithDrawlModal="onSuccessWithDrawalModal" />
        <WitthDrawalConfirm v-if="isWithDrawalConfirm" @closeWithdrawalConfirmModal="onCloseWithdrawalConfirmModal"
                            @confirmWithdrawalmodal="onConfirmWithdrawalmodal" />
        <WithdrawalEnd v-if="isWithDrawalEnd" @endwithdrawal="onEndwithdrawal" />
    </div>
</template>

<script>
import WithdrawalProcess from "@/components/modal/Profile/WithdrawalProcess.vue";
import WitthDrawalConfirm from "@/components/modal/Profile/WithdrawalConfirm.vue";
import WithdrawalEnd from "@/components/modal/Profile/WithdrawalEnd.vue";
import VueCookies from "vue-cookies";
import axios from 'axios';
import router from "@/router";
export default {
  name: 'moProfileInfo',

  components: {
    WithdrawalProcess,
    WitthDrawalConfirm,
    WithdrawalEnd
  },

  data () {
    return {
      comeFrom: VueCookies.get('comeFrom'),
      email: VueCookies.get('email'),
      mobileNo: '',
      withdrawalStatus: false,
      isWithDrawal: false,
      isWithDrawalConfirm: false,
      isWithDrawalEnd: false,
      reason: {
        withdrawalContent: '',
        contentDetail: ''
      }
    };
  },

  created () {
    this.getUserInfo();
  },

  methods: {
    getUserInfo () {
      if (this.comeFrom === 'kakao' || this.comeFrom === 'naver') {
        const email = this.email;
        const comeFrom = this.comeFrom;
        const aToken = VueCookies.get("aToken");
        const headers = { "X-AUTH-TOKEN": aToken };

        axios
          .post('/api/social/getSocialUserInfo', { email, comeFrom }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              const result = res.data.result;
              this.mobileNo = result.mobileNo.replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    openWithdrawal () {
      this.isWithDrawal = true;
    },

    closePopup () {
      this.$emit('closeMoPopup');
    },

    onCloseWithDrawlModal () {
      this.isWithDrawal = false;
    },

    onSuccessWithDrawalModal (reason) {
      this.isWithDrawal = false;
      this.reason = reason;
      this.isWithDrawalConfirm = true;
    },

    onCloseWithdrawalConfirmModal () {
      this.isWithDrawalConfirm = false;
    },

    onConfirmWithdrawalmodal () {
      const userId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      const payload = {
        userId,
        withdrawalContent: this.reason.withdrawalContent,
        contentDetail: this.reason.contentDetail
      };

      axios
        .post("/api/user/withDrawal", payload, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            VueCookies.keys().forEach(cookie => this.$cookies.remove(cookie));
            sessionStorage.removeItem('carCurationSpace');
            this.$store.commit("initData");

            this.isWithDrawalConfirm = false;
            this.isWithDrawalEnd = true;
          }
        })
        .catch(err => {
          console.error("api/user/withDrawal error : ", err);
          alert(`탈퇴에 실패하였습니다.`);
        });
    },
    onEndwithdrawal () {
      router.replace({ name: "Layout3" });
    }
  }
};
</script>

<style scoped>
  .mo-profile {
    display: none;
  }

  .txt-white {
    color: #fff;
  }

  @media all and (max-width: 500px) {
    .mo-profile {
      display: block;

      position: fixed;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background: #151515;
    }

    .profile_header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 13px;
      margin-bottom: 22px;
      padding: 13px 5%;
      border-bottom: 0px solid hsla(0,0%,100%,.08);
    }

    .profile_header-close {
      position: absolute;
      left: 5%;
    }

    .profile_header-close img {
      width: 21px;
    }

    .profile_header h2 {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .profile_title {
      width: 100%;
      padding: 0 5%;
      margin-bottom: 24px;
    }

    .profile_title p {
      color: hsla(0,0%,100%,.87);
      font-size: 16px;
      font-weight: 400;
    }

    .profile_content {
      position: relative;
      width: 100%;
      padding: 0 5%;
    }

    .profile_content input[type='text'] {
      width: 100%;
      margin-bottom: 8px;

    }

    .profile_content .profile_content-mail {
      position: relative;
    }

    .profile_content .profile_content-mail input {
      padding-left: 12%;
    }

    .profile_content .profile_content-mail img {
      position: absolute;
      top: 20%;
      left: 2%;
      width: 30px;
    }

    .delete-btn {
      position: absolute;
      bottom: -50%;
      font-size: 14px;
      color: hsla(0,0%,100%,.6);
    }
  }
</style>
