<template>
    <label for="chk7" style="height:24px;">
        <input id="chk7" type="checkbox" :value="checked" @input="$emit('input', !checked)" />
        <span class="check" :class="{ on: checked }"></span>
    </label>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "checkbox",
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    label: String
  },
  model: {
    prop: 'checked',
    event: 'input'
  }
});
</script>

<style scoped>
input[type="checkbox"] {
  display: none;
}

span.check {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('/media/img/check_off.png') no-repeat;
  background-size: 100%;
  cursor: pointer;
}

span.check.on {
  background: url('/media/img/check_on.png') no-repeat !important;
  background-size: 100% !important;
  border: none !important;
}</style>
