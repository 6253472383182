<template>
    <div class="profile-area">
        <div class="profile-background pc-profile"></div>
        <div class="profile-popup pc-profile">
            <div class="porfile-popup-header">
                <h1 class="popup-header-title">설정</h1>
                <div class="popup-header-close" @click="$emit('closeProfile')">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
            <div class="porfile-popup-body">
                <div class="popup-body-side">
                    <p :class="paStatus === 'Al' ? 'select-tab' : ''" @click="changePc('Al')">알림 설정</p>
                    <p :class="paStatus === 'Profile' ? 'select-tab' : ''" @click="changePc('Profile')">내 계정 관리</p>
                </div>
                <div class="popup-body-content">
                    <pcPush v-if="paStatus === 'Al'"></pcPush>
                    <pcChangePass
                        v-if="paStatus === 'Profile'"
                        :userinfo="userInfo"
                        @closeProfile="$emit('closeProfile')"
                        @change-password="onOpenChangePassword"
                        @openWithDrawlModal="onOpenWithDrawlModal"
                    ></pcChangePass>
                </div>
            </div>
        </div>

        <div class="mo-profile-popup mo-profile">
            <div class="mo-profile-header">
                <div class="mo-profile-header-close">
                    <img src="/media/images/icon_back_24.svg" alt="" @click="$emit('closeProfile')" />
                </div>

                <h2>설정</h2>

                <div></div>
            </div>

            <div class="mo-profile-content">
                <div class="mo-profile-content-item" @click="changeMo('Al')">
                    <p class="txt-white">알림 설정</p>
                    <div>
                        <img src="/media/img/move_icon.svg" alt="" />
                    </div>
                </div>

                <div class="mo-profile-content-item" @click="changeMo('Pass')">
                    <p class="txt-white">내 정보 관리</p>
                    <div>
                        <img src="/media/img/move_icon.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <MoPush v-if="moStatus === 'Al'" @closeMoPopup="moStatus = ''"></MoPush>
        <MoChangePass v-if="moStatus === 'Pass'" @closeMoPopup="moStatus = ''" @closeProfile="closeProfile"></MoChangePass>
        <ProfileInfo v-if="moStatus === 'Pro'" @closeMoPopup="moStatus = ''"></ProfileInfo>
        <WithdrawalProcess
            v-if="isWithDrawal"
            @closeWithDrawlModal="onCloseWithDrawlModal"
            @successWithDrawlModal="onSuccessWithDrawalModal"
        />
        <Withdrawal
            v-if="isWithDrawalConfirm"
            @closewithdrawalmodal="onCloseWithDrawalModal"
            @agreewithdrawalmodal="onAgreeWithDrawalModal"
        />
        <WithdrawalEnd v-if="isWithDrawalEnd" @endwithdrawal="onEndwithdrawal" />
        <auth-phone v-if="isAuthPhone" @close-authphone="onCloseAuthPhone" @getuserinfo="onGetUserInfo" />
        <change-password v-if="isChanePassword" @change-pass="onChangePassword" @close-modal="isChanePassword = false" />
        <changepass-popup v-if="changePassStatus" @closeProfile="onCloseProfile" />
    </div>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import pcPush from '@/components/modal/Profile/pc/Push.vue';
import pcChangePass from '@/components/modal/Profile/pc/ChangePass.vue';
import MoPush from '@/components/modal/Profile/mobile/Push.vue';
import MoChangePass from '@/components/modal/Profile/mobile/ChangePass.vue';
import ProfileInfo from '@/components/modal/Profile/mobile/ProfileInfo.vue';
import WithdrawalProcess from '@/components/modal/Profile/WithdrawalProcess.vue';
import WithdrawalEnd from '@/components/modal/Profile/WithdrawalEnd.vue';
import Withdrawal from '@/components/modal/Profile/Withdrawal.vue';
import AuthPhone from '@/components/modal/Profile/AuthPhone.vue';
import ChangePassWord from '@/components/modal/Profile/ChangePassWord.vue';
import ChangePassPopup from '@/components/modal/Profile/ChangePass.vue';

import router from '@/router';
export default {
  name: 'profile',

  components: {
    pcPush,
    pcChangePass,
    MoPush,
    MoChangePass,
    ProfileInfo,
    WithdrawalProcess,
    WithdrawalEnd,
    Withdrawal,
    'auth-phone': AuthPhone,
    'change-password': ChangePassWord,
    'changepass-popup': ChangePassPopup
  },

  data () {
    return {
      comeFrom: null,
      paStatus: 'Al',
      moStatus: '',
      isApp: '',
      isWithDrawal: false,
      isWithDrawalConfirm: false,
      isWithDrawalEnd: false,
      reason: {
        withdrawalContent: '',
        contentDetail: ''
      },
      isAuthPhone: false,
      userInfo: null,
      isChanePassword: false,
      changePassStatus: false
    };
  },

  created () {
    this.comeFrom = VueCookies.get('comeFrom');
  },

  methods: {
    onCloseProfile () {
      this.changePassStatus = false;
    },

    onChangePassword (val) {
      const { password, newPass } = val;
      const userId = this.$cookies.get('userId');

      axios
        .post(`/api/user/updateUserPass`, { password, newPass, userId })
        .then(res => {
          if (res.data.result) {
            this.changePassStatus = true;
            this.isChanePassword = false;
          } else {
            alert(`비밀번호를 올바르게 입력해주세요`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    onOpenChangePassword () {
      this.isChanePassword = true;
    },
    onGetUserInfo (val) {
      this.userInfo = val;
      this.isAuthPhone = false;
      this.paStatus = 'Profile';
    },
    onCloseAuthPhone () {
      this.isAuthPhone = false;
    },
    changePc (tab) {
      if (tab === 'Profile') {
        this.isAuthPhone = true;
        return;
      }
      this.paStatus = tab;
    },

    changeMo (tab) {
      this.moStatus = tab;
    },

    closeProfile () {
      this.$emit('closeProfile');
    },

    onOpenWithDrawlModal () {
      this.isWithDrawal = true;
    },

    onCloseWithDrawlModal () {
      this.isWithDrawal = false;
    },

    onSuccessWithDrawalModal (reason) {
      this.isWithDrawal = false;
      this.reason = reason;
      this.isWithDrawalConfirm = true;
    },
    onCloseWithdrawalConfirmModal () {
      this.isWithDrawalConfirm = false;
    },
    onCloseWithDrawalModal () {
      this.isWithDrawalConfirm = false;
    },
    onAgreeWithDrawalModal () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      const payload = {
        userId,
        withdrawalContent: this.reason.withdrawalContent,
        contentDetail: this.reason.contentDetail
      };

      axios
        .post('/api/user/withDrawal', payload, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.isWithDrawalConfirm = false;
            VueCookies.keys().forEach(cookie => this.$cookies.remove(cookie));
            sessionStorage.removeItem('carCurationSpace');
            this.$store.commit('initData');
            this.isWithDrawalEnd = true;
          }
        })
        .catch(err => {
          console.error('api/user/withDrawal error : ', err);
          alert(`탈퇴에 실패하였습니다.`);
        });
    },
    onEndwithdrawal () {
      router.replace({ name: 'Layout3' });
    }
  }
};
</script>

<style scoped>
.profile-area {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 101;
}

.profile-background {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.profile-popup {
  width: 800px;
  height: 641px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  padding: 24px 0;
}

.porfile-popup-header {
  border-bottom: 1px solid hsla(0, 0%, 88.2%, 0.08);
  padding: 0 32px 16px;
  margin-bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-header-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.popup-header-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
}

.popup-header-close:hover {
  background: rgba(255, 255, 255, 0.08);
}

.porfile-popup-body {
  display: flex;
  height: 563px;
}

.popup-body-side {
  width: 194px;
  height: 100%;
  border-right: 1px solid hsla(0, 0%, 100%, 0.06);
  padding: 24px 32px;
}

.popup-body-side.profile_change {
  height: 600px;
}

.popup-body-side p {
  display: block;
  width: 100%;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 16px;
  margin-bottom: 20px;
  white-space: nowrap;
  cursor: pointer;
  padding-left: 0;
}

.popup-body-content {
  width: 606px;
  height: 100%;
  padding: 24px 32px;
}

.popup-body-content.profile_change {
  height: 600px;
}

.select-tab {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500;
}

.pc-profile {
  display: block;
}

.mo-profile {
  display: none;
}

.txt-white {
  color: #fff;
}

@media all and (max-width: 1024px) {
  .profile-popup {
    width: 80%;
  }
}

@media all and (max-width: 500px) {
  .pc-profile {
    display: none;
  }

  .mo-profile {
    display: block;
  }

  .mo-profile-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #151515;
  }

  .mo-profile-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 13px;
    padding: 13px 5%;
  }

  .mo-profile-header h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .mo-profile-header-close {
    position: absolute;
    left: 5%;
  }

  .mo-profile-header-close img {
    width: 21px;
  }

  .mo-profile-content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 18px 5%;
    border-top: 1px solid hsla(0, 0%, 88.2%, 0.08);
  }

  .mo-profile-content-item:last-child {
    border-bottom: 1px solid hsla(0, 0%, 88.2%, 0.08);
  }
}
</style>
