<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>비밀번호가 변경되었습니다.</h3>
            <div class="single-file-check" @click="$emit('closeProfile')">
                <p>확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'changePass',
  setup () {}
});
</script>

<style scoped>
  .modal {
    z-index: 1057;
  }
  p {
    text-align: center;
  }
</style>
