<template>
    <div class="mo-profile">
        <div class="profile_header">
            <div class="profile_header-close">
                <img src="/media/images/icon_back_24.svg" alt="" @click="closePopup" />
            </div>

            <h2 class="txt-white">내 계정 관리</h2>
        </div>
        <div class="profile_body">
            <auth-phonemo v-if="isAutoPhone" @getuserinfo="onGetUserInfo" />
            <profile-infomo
                v-if="isProfileInfo"
                :userinfo="userInfo"
                @changepassword="onChangepassword"
                @withdrawl="openWithdrawal"
            />
            <change-password v-if="isChangePassword" @change-pass="onChangePass" />
        </div>

        <WithdrawalProcess
            v-if="isWithDrawal"
            @closeWithDrawlModal="onCloseWithDrawlModal"
            @successWithDrawlModal="onSuccessWithDrawalModal"
        />
        <WitthDrawalConfirm
            v-if="isWithDrawalConfirm"
            @closeWithdrawalConfirmModal="onCloseWithdrawalConfirmModal"
            @confirmWithdrawalmodal="onConfirmWithdrawalmodal"
        />
        <WithdrawalEnd v-if="isWithDrawalEnd" @endwithdrawal="onEndwithdrawal" />
        <ChangePassPopup v-if="changePassStatus" @closeProfile="closeProfile"></ChangePassPopup>
        <withdrawal-info v-if="isWithDrawalInfo" @close-modal="onCloseModal" />
    </div>
</template>

<script>
import ChangePassPopup from '@/components/modal/Profile/ChangePass.vue';
import WithdrawalProcess from '@/components/modal/Profile/WithdrawalProcess.vue';
import WitthDrawalConfirm from '@/components/modal/Profile/WithdrawalConfirm.vue';
import WithdrawalEnd from '@/components/modal/Profile/WithdrawalEnd.vue';
import WithdrawalInfo from '@/components/modal/Profile/WithdrawlInfo.vue';
import AuthPhoneMo from '@/components/profile/AuthPhoneMo.vue';
import ChangePassWord from '@/components/profile/ChangePassWord.vue';
import ProfileInfoMo from '@/components/profile/ProfileInfoMo.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import router from '@/router';
export default {
  name: 'moChangePassword',

  components: {
    ChangePassPopup,
    WithdrawalProcess,
    WitthDrawalConfirm,
    WithdrawalEnd,
    'withdrawal-info': WithdrawalInfo,
    'auth-phonemo': AuthPhoneMo,
    'profile-infomo': ProfileInfoMo,
    'change-password': ChangePassWord
  },

  data () {
    return {
      password: '',
      newPassword: '',
      reInputPassword: '',

      alertMessage_1: false,
      alertMessage_2: false,
      alertMessage_3: false,
      alertMessage_4: false,

      changePassStatus: false,
      isWithDrawal: false,
      isWithDrawalConfirm: false,
      isWithDrawalEnd: false,
      isWithDrawalInfo: false,
      reason: {
        withdrawalContent: '',
        contentDetail: ''
      },
      isAutoPhone: true,
      isProfileInfo: false,
      isChangePassword: false,
      userInfo: null
    };
  },

  methods: {
    onChangepassword () {
      this.isProfileInfo = false;
      this.isChangePassword = true;
    },
    onCloseModal () {
      this.isWithDrawalInfo = false;
    },
    onGetUserInfo (val) {
      this.userInfo = val;
      this.isAutoPhone = false;
      this.isProfileInfo = true;
    },
    async getPaidSpacesInApp (userId) {
      return await axios.post('/api/product/numberOfPaidSpacesInApp', { userId });
    },
    checkLength () {
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage_3 = false;
      }
    },

    checkPassword () {
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.newPassword)
        ? (this.alertMessage_1 = false)
        : (this.alertMessage_1 = true);
      if (this.newPassword.length === 0) {
        this.alertMessage_1 = false;
      }

      if (this.newPassword === this.reInputPassword) {
        this.alertMessage_2 = false;
      }

      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage_3 = false;
      }
    },

    matchPassword () {
      this.newPassword === this.reInputPassword ? (this.alertMessage_2 = false) : (this.alertMessage_2 = true);

      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage_3 = false;
      }
    },

    async openWithdrawal () {
      const result = await this.getPaidSpacesInApp(this.$cookies.get('userId'));
      const { data } = result;
      const { result: resultInapp } = data;
      if (resultInapp > 0) {
        this.isWithDrawalInfo = true;
      } else {
        this.isWithDrawal = true;
      }
    },

    onChangePass (val) {
      const { password, newPass } = val;
      const userId = this.$cookies.get('userId');

      axios
        .post(`/api/user/updateUserPass`, {
          password,
          newPass,
          userId
        })
        .then(res => {
          if (res.data.result) {
            this.changePassStatus = true;
          } else {
            alert(`비밀번호를 올바르게 입력해주세요`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    changePass () {
      var password = btoa(this.password);
      var newPass = btoa(this.password);
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      // 규칙에 걸렸는지
      if (!this.alertMessage_1 && !this.alertMessage_2) {
        // 입력 여부 체크
        if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
          this.alertMessage_3 = false;

          axios
            .post('/api/user/updateUserPass', { password, newPass, userId }, { headers })
            .then(res => {
              if (res.data.result) {
                this.changePassStatus = true;
              } else {
                this.alertMessage_4 = true;
              }
            })
            .catch(e => {
              console.log(e);
            });
        } else {
          this.alertMessage_3 = true;
        }
      }
    },

    closeProfile () {
      this.changePassStatus = false;
      this.$emit('closeProfile');
    },

    closePopup () {
      this.$emit('closeMoPopup');
    },

    onCloseWithDrawlModal () {
      this.isWithDrawal = false;
    },

    onSuccessWithDrawalModal (reason) {
      this.isWithDrawal = false;
      this.reason = reason;
      this.isWithDrawalConfirm = true;
    },

    onCloseWithdrawalConfirmModal () {
      this.isWithDrawalConfirm = false;
    },
    onConfirmWithdrawalmodal () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      const payload = {
        userId,
        withdrawalContent: this.reason.withdrawalContent,
        contentDetail: this.reason.contentDetail
      };

      axios
        .post('/api/user/withDrawal', payload, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            VueCookies.keys().forEach(cookie => this.$cookies.remove(cookie));
            sessionStorage.removeItem('carCurationSpace');
            this.$store.commit('initData');

            this.isWithDrawalConfirm = false;
            this.isWithDrawalEnd = true;
          }
        })
        .catch(err => {
          console.error('api/user/withDrawal error : ', err);
          alert(`탈퇴에 실패하였습니다.`);
        });
    },
    onEndwithdrawal () {
      router.replace({ name: 'Layout3' });
    }
  }
};
</script>

<style scoped>
.mo-profile {
  display: none;
}

.txt-white {
  color: #fff;
}

.profile_body {
  margin-top: 24px;
}

/* 반응형 */
@media all and (max-width: 500px) {
  .mo-profile {
    display: block;

    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: #151515;
  }

  .profile_header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 0;
    padding: 13px 5%;
    border-bottom: 0px solid hsla(0, 0%, 100%, 0.08);
  }

  .profile_header-close {
    position: absolute;
    left: 5%;
  }

  .profile_header-close img {
    width: 21px;
  }

  .profile_header h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .profile_title p {
    color: hsla(0, 0%, 100%, 0.87);
    font-size: 16px;
    font-weight: 400;
  }

  .profile_content {
    width: 100%;
    padding: 20px 5% 0;
  }

  .profile_content input[type='password'] {
    width: 100%;
    margin-bottom: 8px;
  }

  .alert-message {
    padding: 0 5%;
    color: #ff2d55;
  }

  .profile-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px 5%;
  }

  .footer-btn {
    width: 100%;
    height: 54px;
    border-radius: 8px;
    background: #ff2d55;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    font-weight: 500;
    transition: all 0.3s;
  }

  .delete-btn {
    position: absolute;
    bottom: 100%;
    font-size: 14px;
    color: hsla(0, 0%, 100%, 0.6);
  }
}
</style>
