<template>
    <div class="autophonemo_wrap">
        <h1 class="autophonemo_title">
            개인정보 보호를 위해 <br />
            휴대폰 번호를 입력해주세요.
        </h1>
        <p class="autophonemo_desc">본인인증시 사용된 휴대폰 번호를 입력해주세요.</p>

        <div class="autophonemo_input_wrap flex_justify_center">
            <input
                @keyup.enter="getUserInfo"
                v-model="mobileNo"
                type="text"
                class="autophonemo_input"
                placeholder="‘-’를 제외한 휴대폰 번호"
            />
        </div>

        <div class="autophonemo_nextbtn_wrap flex_justify_center">
            <button class="autophonemo_nextbtn" :disabled="isDisabled" @click="getUserInfo">다음</button>
        </div>
    </div>
</template>

<script>
import { getUser } from '@/service/api/userApi';

export default {
  name: 'AuthPhoneMo',
  props: ['getuserinfo'],
  data () {
    return {
      mobileNo: ''
    };
  },

  mounted () {},

  methods: {
    getUserInfo () {
      const userId = this.$cookies.get('userId');
      const mobileNo = this.mobileNo;

      getUser(userId, mobileNo)
        .then(res => {
          const { data } = res;
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            this.$emit('getuserinfo', result);
          } else {
            alert('휴대폰 번호 확인에 실패하였습니다.');
          }
        })
        .catch(err => {
          if (err.response) {
            const { resultMsg } = err.response.data;
            alert(resultMsg);
          } else {
            alert('휴대폰 번호 확인에 실패하였습니다.');
          }
        });
    }
  },
  computed: {
    isDisabled () {
      if (this.mobileNo) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style scoped src="@/assets/css/profile/authphonemo.css"></style>
