<template>
    <div>
        <Logout v-if="showLogout" @closeLogout="showLogout = false" />
        <Profile v-if="settingStatus && !isUseKisok" :checkMobile="checkMobile" @closeProfile="settingStatus = false" />
        <div class="toggle_menu" id="toggle_menu">
            <div class="toggle_box">
                <div class="toggle_close" @click.once="$emit('closeAside')">
                    <h2>메뉴</h2>
                    <img src="/media/images/icon_close_24.svg" />
                </div>
                <div class="toggle_email" v-if="!isUseKisok">
                    <h2 style="cursor: default">{{ email }}</h2>
                    <img
                        src="/media/img/toggle_setting.svg"
                        width="24px"
                        height="24px"
                        @click="showSettingBox"
                        class="show_select_box"
                    />
                </div>
                <div v-if="isAttendenceEvent" class="event_attendence_box" @click="goDetailEvent">
                    <div class="event_attendence_text3">Event</div>
                    <span class="event_attendence_box_text1"> 나의 출석 현황 </span>
                    <figure class="event_attendence_box_forwardwrap">
                        <img class="event_attendence_box_forwardimg" src="/media/img/event/arrow_forward.png" alt="arrow_forward" />
                    </figure>

                    <span class="event_attendence_box_text2">{{ attendanceDate }}일</span>
                </div>
                <ul class="menu_list" :class="isAttendenceEvent ? 'active' : ''">
                    <li v-if="!isUseKisok" @click="selectMySpace">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon1.svg" alt="" />
                        </div>
                        <p>마이스페이스</p>
                    </li>
                    <li v-if="isUseKisok" @click="selectAutoMusicSetting">
                        <div class="toggle_icon_box">
                            <img src="/media/img/icon_auto_music_setting.png" alt="" />
                        </div>
                        <p>자동재생 설정</p>
                    </li>
                    <li @click="selectMyMusic" v-if="this.$store.state.division !== 'Building'">
                        <div class="toggle_icon_box">
                            <img src="/media/img/ic_menu_mymusic.svg" alt="" />
                        </div>
                        <p>마이뮤직</p>
                    </li>
                    <li @click="selectProduct" v-if="this.$store.state.division !== 'Building' && !isUseKisok">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon2.svg" alt="" />
                        </div>
                        <p>이용권 소개</p>
                    </li>
                    <li @click="selectPayment" v-if="this.$store.state.division !== 'Building' && !isUseKisok">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon3.svg" alt="" />
                        </div>
                        <p>이용권 현황</p>
                    </li>
                    <li @click="selectCard" v-if="this.$store.state.division !== 'Building' && !isUseKisok">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon4.svg" alt="" />
                        </div>
                        <p id="aisdeCard" style="margin-right: 8px">결제카드</p>
                    </li>
                    <li @click="selectCoupon" v-if="this.$store.state.division !== 'Building' && !isUseKisok">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon5.svg" alt="" />
                        </div>
                        <p>쿠폰</p>
                    </li>
                    <li @click="selectEvent" v-if="this.$store.state.division !== 'Building' && !isUseKisok">
                        <div class="toggle_icon_box">
                            <img src="/media/img/icon_event_24.svg" alt="" />
                        </div>
                        <p>이벤트</p>
                        <span class="event_tag2" v-if="eventNewTag">
                            <img src="/media/img/event/event_N.svg" />
                        </span>
                    </li>
                    <li @click="selectFAQ">
                        <div class="toggle_icon_box">
                            <img src="/media/img/toggle_icon6.svg" alt="" />
                        </div>
                        <p>고객센터</p>
                    </li>
                </ul>
                <div v-if="!isUseKisok" class="policy">
                    <p class="toggle_policy toggle_policy_text toggle_policy_text1" @click="selectService">이용약관</p>
                    <p class="toggle_policy toggle_policy_text" @click="selectprivacy">개인정보처리방침</p>
                </div>
                <div class="logout">
                    <svg
                        class="logout_icon cursor"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.33317 13.4173C1.15636 13.4173 0.98679 13.3471 0.861766 13.2221C0.736742 13.097 0.666504 12.9275 0.666504 12.7507V0.750651C0.666504 0.57384 0.736742 0.404271 0.861766 0.279246C0.98679 0.154222 1.15636 0.0839844 1.33317 0.0839844H10.6665C10.8433 0.0839844 11.0129 0.154222 11.1379 0.279246C11.2629 0.404271 11.3332 0.57384 11.3332 0.750651V2.75065H9.99984V1.41732H1.99984V12.084H9.99984V10.7507H11.3332V12.7507C11.3332 12.9275 11.2629 13.097 11.1379 13.2221C11.0129 13.3471 10.8433 13.4173 10.6665 13.4173H1.33317ZM9.99984 9.41732V7.41732H5.33317V6.08398H9.99984V4.08398L13.3332 6.75065L9.99984 9.41732Z"
                            fill="white"
                        />
                    </svg>
                    <p class="cursor" @click="showLogout = true">로그아웃</p>
                </div>
                <!-- 2023.10.19 수민실장임 요청으로 인한 숨기 처리 -->
                <div class="download_player_side" v-if="isDownloadButton">
                    <a @click="addDownloadCount()">매장용 플레이어 다운로드</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Logout from '@/components/modal/Logout/Logout.vue';
import Profile from '@/components/modal/Profile/Profile.vue';
import VueCookies from 'vue-cookies';
import router from '@/router';
import dayjs from 'dayjs';
import $ from 'jquery';
import axios from '@/service/axios';
import { getAttendenceInfo, getEventList } from '@/service/api/menuApi';
import { getWebViewEvent } from '@/service/api/mobileApi';
export default defineComponent({
  components: {
    Logout,
    Profile
  },
  data () {
    return {
      showLogout: false,
      email: VueCookies.get('email'),
      passMoadlStatus: false,
      pushModalStatus: false,
      withdrawalStatus: false,
      settingStatus: false,
      checkMobile: 'N',
      mode: '',
      eventNewTag: this.$store.state.eventNewTag,
      eventTag: true,
      isDownloadButton: false,
      attendanceDate: 0,
      eventList: [],
      eventId: ''
    };
  },
  created () {
    getWebViewEvent().then(res => {
      const { resultCd } = res.data;
      if (resultCd === '0000') {
        const { result } = res.data;
        if (result[result.length - 1] === 'event_000033') {
          this.eventId = result[result.length - 2];
        } else {
          this.eventId = result[result.length - 1];
        }
      }
    });
    this.modeCheck();

    getAttendenceInfo(this.$cookies.get('userId'))
      .then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          const { attendanceDate } = result;
          this.attendanceDate = attendanceDate;
        } else {
          this.attendanceDate = 0;
        }
      })
      .catch(err => {
        console.error('error : ', err);
        this.attendanceDate = 0;
      });
    this.filterEvent();
  },
  mounted () {
    this.set();
    this.checkDday();
    this.showDownloadButton();
  },
  methods: {
    filterEvent () {
      getEventList(100, 0).then(res => {
        const { result, resultCd } = res.data;
        if (resultCd === '0000') {
          const { eventList } = result;
          this.eventList = eventList;
        }
      });
    },
    goDetailEvent () {
      this.$store.commit('setEventId', this.eventId);
      this.$router.push({ name: 'EventView' });
      this.$emit('closeAside');
    },
    checkDevice () {
      // 디바이스 종류 설정
      var pcDevice = 'win16|win32|win64|mac|macintel';
      // 접속한 디바이스 환경
      var thisDevice = navigator.platform;
      if (thisDevice) {
        pcDevice.indexOf(navigator.platform.toLowerCase()) < 0
          ? (this.isDownloadButton = false)
          : (this.isDownloadButton = true);
      }
    },
    addDownloadCount () {
      // 2023.10.19 수민실장님 요청으로 인한 숨김처리
      window.location.href = `/media/download/setup_aplayz.zip?date=${new Date().getTime()}`;
      axios.post('/api/installProgram/addDownloadCount').catch(err => {
        console.log(err);
      });
    },
    showDownloadButton () {
      // 다운로드 버튼 노출 여부
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/installProgram/getSpaceCountByUser', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            // 매장용(체험),매장용(구독) 스페이스 플레이어 이용자에게만 노출
            if (result == null || result.storeCount < 1) {
              this.isDownloadButton = false;
            } else {
              this.checkDevice();
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    checkDday () {
      var dDay = new Date('2022-08-01 00:00:00');
      var today = new Date();
      today > dDay ? (this.eventTag = false) : (this.eventTag = true);
    },
    checkEventDate () {
      var today = dayjs();
      var eventStartDate = dayjs(this.$store.state.eventInfo.eventStartDate);
      var eventEndDate = dayjs(this.$store.state.eventInfo.eventEndDate);
      var startDay = eventStartDate.diff(today, 'day', true);
      var endDay = eventEndDate.diff(today, 'day', true);

      Math.floor(startDay) < -7 || Math.floor(endDay) < 0 ? (this.eventNewTag = false) : (this.eventNewTag = true);
    },
    modeCheck () {
      var url = window.location.host;
      url.indexOf('localhost') !== -1 || url.indexOf('dev') !== -1 ? (this.mode = 'dev') : (this.mode = 'prod');
    },
    set () {
      // 숨기기
      $('.select_show_box').hide();
    },
    selectAutoMusicSetting () {
      router.push({ name: 'AutoPlay' }).catch(() => {});
      this.$emit('closeAside');
    },
    showSettingBox () {
      this.settingStatus = true;
    },
    selectMyMusic () {
      router.push({ name: 'MyMusic' });
      this.$emit('closeAside');
    },
    selectMySpace () {
      router.push({ name: 'MySpace' });
      this.$emit('closeAside');
    },
    selectProduct () {
      router.push({ name: 'Product' });
      this.$emit('closeAside');
    },
    selectPayment () {
      router.push({ name: 'Payment' });
      this.$emit('closeAside');
    },
    selectCard () {
      router.push({ name: 'Card' });
      this.$emit('closeAside');
    },
    selectCoupon () {
      router.push({ name: 'Coupon' });
      this.$emit('closeAside');
    },
    selectEvent () {
      router.push({ name: 'Event' });
      this.$emit('closeAside');
    },
    selectFAQ () {
      router.push({ name: 'FAQ' });
      this.$emit('closeAside');
    },
    selectService () {
      router.push({ name: 'Service' });
      this.$emit('closeAside');
    },
    selectprivacy () {
      router.push({ name: 'Privacy' });
      this.$emit('closeAside');
    },
    selectPush () {
      // 푸쉬 알람 이동
      this.pushModalStatus = true;
      this.$emit('closeAside');
    },
    selectPassChange () {
      // 비밀번호 변경
      this.passMoadlStatus = true;
      this.$emit('closeAside');
    }
  },
  setup () {},
  computed: {
    isUseKisok () {
      const userId = VueCookies.get('userId');
      const limitList = ['user_004699', 'user_000236'];

      const findIdx = limitList.findIndex(item => item === userId);
      if (findIdx > -1) {
        return true;
      }

      return false;
    },
    isAttendenceEvent () {
      /* 2024.09.04 9월 이후로 종료 예정이나 언제다시 사용할 수 도 있음.(FROM 박성현 프로님) */
      // const eventStartDate = new Date('2023-12-01 00:00:00');
      // const eventEndDate = new Date('2024-12-31 23:59:59');
      // const curDate = new Date();

      // if (eventStartDate.getTime() <= curDate.getTime() && curDate.getTime() <= eventEndDate.getTime()) {
      //   return true;
      // }
      return false;
    }
  },
  destroyed () {
    document.querySelector('body').style.overflow = '';
  }
});
</script>

<style scoped>
.toggle_box ul li .event_tag {
  background: #ff2d55;
  padding-right: 3px;
  color: #fff;
  border-radius: 500px;
  width: 52px;
  height: 20px;
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  font-style: italic;
  font-weight: 600;
  font-size: 12px !important;
}

.toggle_box ul li .event_tag2 {
  width: 12px;
  height: 14px;
  position: absolute;
  top: 30%;
  padding-left: 34%;
  vertical-align: middle;
}

@media all and (max-width: 1023px) {
  #aisdeCard {
    margin-right: 0 !important;
  }

  .toggle_box .toggle_policy {
    font-size: 14px !important;
  }

  .toggle_box ul li .event_tag {
    box-sizing: border-box;
    box-shadow: 0px 1px 20px rgb(0 0 0 / 80%);
    background: #ff2d55;
    padding-right: 3px;
    color: #fff;
    border-radius: 500px;
    position: absolute;
    width: 40px;
    height: 14.48px;
    top: 5%;
    vertical-align: middle;
    line-height: 13px;
    text-align: center;
    font-style: italic;
    font-size: 10px !important;
    font-weight: 700;
  }

  .toggle_box ul li .event_tag2 {
    width: 10px;
    height: 14px;
    position: absolute;
    top: 15%;
    padding-left: 25%;
    vertical-align: middle;
    line-height: 13px;
  }
}

.event_attendence_box {
  border-radius: 8px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(255, 255, 255, 0.08);
  margin-top: 40px;
  padding: 8px 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.event_attendence_box_text1 {
  color: var(--whitefff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}

.event_attendence_box_forwardwrap {
  width: 16px;
  height: 16px;
  margin-right: 43px;
}

.event_attendence_box_forwardimg {
  width: 100%;
  height: 100%;
}

.event_attendence_box_text2 {
  font-size: 18px;
  color: var(--secondary);
  font-weight: 700;
}

.event_attendence_text3 {
  font-family: 'LaTo';
  position: absolute;
  top: -10px;
  left: 13px;
  background-color: var(--secondary);
  padding: 3px;
  font-size: 12px;
  font-style: italic;
  color: var(--whitefff);
  font-weight: 700;
  border-radius: 500px;
  display: inline-block;
  width: 48px;
  text-align: center;
}

.disabled-img {
  opacity: 0.2 !important;
  cursor: auto;
}

.disabled-text {
  color: rgba(255, 255, 255, 0.3) !important;
  cursor: auto;
}

.logout .logout_icon {
  margin-right: 12px;
}

.logout p {
  display: inline-block;
  margin: 0;
}

.show_select_box {
  cursor: pointer;
}

.select_show_box {
  position: absolute;
  top: 120%;
  right: 14%;
  padding: 6px 0;
  background: #313131;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.select_show_box p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
}

.select_show_box p:hover {
  background: rgba(255, 255, 255, 0.08);
}

.select_show_box p svg {
  margin-right: 6px;
}

/*toggle_menu*/
.toggle_menu {
  position: relative;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 347px;
  height: 100%;
  background: #151515;
  filter: drop-shadow(-20px 0px 30px rgba(0, 0, 0, 0.4));
  padding: 80px 0 0 80px;
  transition: all 0.3s;
}

.toggle_menu.active {
  right: 0;
}

.toggle_close {
  position: absolute;
  top: 30px;
  right: 80px;
  display: flex;
  padding: env(safe-area-inset-top) 5%;
  justify-content: center !important;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
}

.toggle_close:hover {
  background: rgba(255, 255, 255, 0.08);
}

.toggle_close:active {
  background: rgba(255, 255, 255, 0.16);
}

.toggle_close h2 {
  display: none;
}

.toggle_email {
  width: 280px;
  display: flex;
  justify-content: flex-start;
  align-items: Center;
}

.toggle_email h2 {
  width: 152px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0;
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 1.8;
}

.toggle_email img {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.toggle_box ul {
  margin: 63px 0 52px;
  padding: 0;
}

.toggle_box .menu_list {
  margin: 19px 0 15px;
  padding: 0;
}

.toggle_box .menu_list.active {
  margin-top: 19px;
}

.toggle_box ul .toggle_icon_box {
  width: 22px;
  margin-right: 13px;
}

.toggle_box ul .toggle_icon_box img {
  width: 100%;
}

.toggle_box ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.toggle_box ul li:last-child {
  margin: 0;
}

.toggle_box ul li p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.87);
  letter-spacing: -0.5px;
}

.toggle_box > div:nth-child(4) {
  cursor: pointer;
  margin-bottom: 52px;
}

.toggle_box > div:nth-child(4) p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  cursor: pointer;
}

.toggle_box > div:nth-child(4) p:nth-child(1) {
  margin-bottom: 16px;
}

.toggle_box > div:nth-child(5) {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
}

.toggle_box > div:nth-child(5) svg {
  margin-right: 12px;
}

.toggle_box > div:nth-child(5) p {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
}

.toggle_box > .download_player_side {
  line-height: 45px;
  display: inline-block;
  margin-top: 10px;
}

.toggle_box > .download_player_side a {
  align-items: Center;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
}

.toggle_box > .download_player_side a:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
}

ul li p {
  margin-bottom: 0;
}

@media all and (max-width: 1023px) {
  /*toggle_menu*/
  .toggle_menu {
    right: 0%;
    width: 100%;
    padding: 12px 0;
    filter: none;
  }

  .toggle_close {
    position: static;
    width: 100%;
    display: flex;
    padding: env(safe-area-inset-top) 5%;
    justify-content: space-between !important;
    align-items: Center;
    margin-bottom: 0;
    background: transparent;
  }

  .toggle_close:hover {
    background: transparent;
  }

  .toggle_close:active {
    background: transparent;
  }

  .toggle_close h2 {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  .toggle_email {
    width: 100%;
    padding: 12px 5% 23px;
    display: flex;
    justify-content: space-between;
    align-items: Center;
  }

  .toggle_email h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    width: 300px;
  }

  .toggle_email img {
    display: block;
  }

  .toggle_box ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 16px 5%;
    margin: 0 0 30px;
  }

  .toggle_box ul li {
    flex-direction: column;
    justify-content: center;
    height: 83px;
    margin-bottom: 0;
    position: relative;
  }

  .toggle_box ul li .toggle_icon_box {
    width: 24px;
    margin: 0 auto;
    margin-bottom: 6px;
  }

  .toggle_box ul li .toggle_icon_box img {
    width: 100%;
    height: 24px;
  }

  .toggle_box ul li p {
    font-size: 14px;
    text-align: center;
  }

  .toggle_box > div:nth-child(4) {
    padding: 0 5%;
  }

  .toggle_box > div:nth-child(4) p {
    font-size: 16px;
  }

  .toggle_box > div:nth-child(4) p:nth-child(1) {
    margin-bottom: 14px;
  }

  .toggle_box > div:nth-child(5) {
    position: absolute;
    bottom: 50px;
    left: 5%;
  }

  .toggle_box > div:nth-child(5) p {
    text-decoration: none;
  }

  .logout p {
    margin-bottom: 0px;
  }

  .toggle_email img {
    margin-left: 10px;
    width: 24px;
    height: 24px;
  }
}

@media all and (min-width: 1025px) {
  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    .toggle_close {
      position: absolute;
      top: 30px;
      right: 80px;
      padding: 13px;
      display: flex;
      justify-content: center !important;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: transparent;
    }
  }
}

@media (max-width: 768px) {
  .event_attendence_box {
    width: 90%;
    margin: 0 auto;
    display: flex;
  }

  .event_attendence_box_forwardwrap {
    margin-right: auto;
  }
}

@media (max-width: 500px) {
  .toggle_box > .download_player_side {
    display: none;
  }
}
</style>
