<template>
    <div class="withdrawal_container">
        <div class="withdrawal_modal">
            <div class="head">
                <h1 class="title">
                    회원탈퇴
                </h1>
                <svg @click="$emit('closeWithDrawlModal')" class="close_btn" width="20" height="20" viewBox="0 0 19 19"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                        fill="white" />
                </svg>
            </div>
            <div class="mo_head">
                <img class="back" src="/media/images/icon_back_24.svg" alt="뒤로가기" @click="$emit('closeWithDrawlModal')">
                <h1 class="title mo_title">
                    회원탈퇴
                </h1>
            </div>
            <div class="body">
                <h1 class="title">
                    정말 탈퇴하시겠어요?
                </h1>
                <p class="reason">
                    탈퇴하시려는 이유를 말씀해주세요. 서비스 개선에 소중한 자료로 활용하겠습니다.
                </p>
                <ul class="reason_list">
                    <li class="reason_item">

                        <img :src="hasReason1 ? '/media/img/check_on.png' : '/media/img/check_off.png'" alt="check_off"
                             @click="clickReason('사용이 불편하고 장애가 많아서')">
                        <label for="reason1" class="text">사용이 불편하고 장애가 많아서</label>
                        <input v-model="reasonChecked" type="checkbox" id="reason1" hidden value="사용이 불편하고 장애가 많아서">
                    </li>
                    <li class="reason_item">
                        <img :src="hasReason2 ? '/media/img/check_on.png' : '/media/img/check_off.png'" alt="check_off"
                             @click="clickReason('가격이 비싸서')">
                        <label for="reason2" class="text">가격이 비싸서</label>
                        <input v-model="reasonChecked" type="checkbox" id="reason2" hidden value="가격이 비싸서">
                    </li>
                    <li class="reason_item">
                        <img :src="hasReason3 ? '/media/img/check_on.png' : '/media/img/check_off.png'" alt="check_on"
                             @click="clickReason('개인 정보를 삭제하고 싶어서')">
                        <label for="reason3" class="text">개인 정보를 삭제하고 싶어서</label>
                        <input v-model="reasonChecked" type="checkbox" id="reason3" hidden value="개인 정보를 삭제하고 싶어서">
                    </li>
                    <li class="reason_item">
                        <img :src="hasReason4 ? '/media/img/check_on.png' : '/media/img/check_off.png'" alt="check_on"
                             @click="clickReason('사용 빈도가 낮아서')">
                        <label for="reason4" class="text">사용 빈도가 낮아서</label>
                        <input v-model="reasonChecked" type="checkbox" id="reason4" hidden value="사용 빈도가 낮아서">
                    </li>
                    <li class="reason_item">
                        <img :src="hasReason5 ? '/media/img/check_on.png' : '/media/img/check_off.png'" alt="check_off"
                             @click="clickReason('기타')">
                        <label for="reason5" class="text">기타</label>
                        <input v-model="reasonChecked" type="checkbox" id="reason5" hidden value="기타">
                    </li>

                    <div class="reason_other" :class="hasReason5 ? 'focus' : ''">
                        <textarea class="reason_textarea" placeholder="탈퇴사유를 입력해주세요.(최대 50자)" v-model="contentDetail"
                                  :disabled="hasReason5 ? false : true"></textarea>
                        <p class="counter">
                            <span class="current_count">{{ counterReason }}</span>/50
                        </p>
                    </div>

                </ul>

            </div>

            <div class="footer">
                <button class="footer_btn cancel_btn" @click="$emit('closeWithDrawlModal')">취소</button>
                <button class="footer_btn cofirm_btn" @click="confirmWithDrawl">확인</button>
                <button class="mo_footer_btn" @click="confirmWithDrawl">다음</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'WithdrawalProcess',
  emits: ['closeWithDrawlModal', 'successWithDrawlModal'],
  data () {
    return {
      reasonChecked: [],
      isFocus: false,
      contentDetail: ''
    };
  },

  mounted () {

  },

  methods: {
    confirmWithDrawl () {
      const hasOther = this.reasonChecked.find(item => item === '기타');

      if (this.reasonChecked.length === 0) {
        alert('탈퇴사유를 선택해주세요');
        return false;
      }

      if (hasOther && this.contentDetail.length === 0) {
        alert("탈퇴사유를 입력해주세요.");
        return false;
      }

      const reasonObj = {
        withdrawalContent: this.reasonChecked.join(','),
        contentDetail: this.hasReason5 ? this.contentDetail : null
      };
      this.$emit('successWithDrawlModal', reasonObj);
    },
    clickReason (value) {
      const findIdx = this.reasonChecked.findIndex(item => item === value);
      if (findIdx < 0) {
        this.reasonChecked.push(value);
      } else {
        this.reasonChecked.splice(findIdx, 1);
      }
    }
  },
  computed: {
    hasReason1 () {
      return !!this.reasonChecked.find(item => item === '사용이 불편하고 장애가 많아서');
    },
    hasReason2 () {
      return !!this.reasonChecked.find(item => item === '가격이 비싸서');
    },
    hasReason3 () {
      return !!this.reasonChecked.find(item => item === '개인 정보를 삭제하고 싶어서');
    },
    hasReason4 () {
      return !!this.reasonChecked.find(item => item === '사용 빈도가 낮아서');
    },
    hasReason5 () {
      return !!this.reasonChecked.find(item => item === '기타');
    },
    counterReason () {
      return this.contentDetail.length;
    }
  },
  watch: {
    contentDetail (val) {
      if (val.length > 50) {
        this.contentDetail = this.contentDetail.substring(0, 50);
      }
    }
  }
};
</script>
<style scoped>
.close_btn {
  cursor: pointer;
}

.withdrawal_modal>.body {
  padding: 25px 24px 65px 24px;
}

.withdrawal_modal>.body>.title {
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 20px;
}

.withdrawal_modal>.body>.reason {
  color: rgba(255, 255, 255, 0.64);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 10px;
}

.withdrawal_modal>.body>.reason_list {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.reason_item {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}

.reason_item>img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 12px;
}

.reason_item>.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}

.reason_other {
  margin-top: 10px;
  width: 100%;
  height: 172px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.04);
  padding: 14px 16px;
  opacity: 0.3;
}

.reason_other.focus {
  border: 1px solid #FF2D55;
  opacity: 1;
}

.reason_textarea {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  height: 125px;
  resize: none;
  color: rgba(255, 255, 255, 0.6);
}

.reason_textarea::placeholder {
  font-size: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
}

.reason_other>.counter {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.6);
}

.current_count {
  font-weight: 700;
}

.footer>.footer_btn {
  padding: 14px 30px;
  font-weight: 500;
  color: var(--white);
  font-size: 16px;
  line-height: 1;
}

.cancel_btn {
  background-color: rgba(21, 21, 21, 0);
}

.cofirm_btn {
  background-color: var(--secondary);
}

.mo_footer_btn {
  display: none;
}

/* 반응형 */
@media all and (max-width:500px) {
  .withdrawal_modal>.body {
    padding-top: 11px;
    padding-bottom: 0;
  }

  .footer {
    margin-top: 32px;
  }

  .footer_btn {
    display: none;
  }

  .mo_footer_btn {
    width: 100%;
    background-color: var(--secondary);
    padding: 12px 145px;
    color: var(--white);
    display: inline-block;
  }

}
</style>
