<template>
    <div>
        <ul class="flex_column w_90 mlr_auto">
            <li class="flex_column profile_info_item">
                <label class="profile_info_label">아이디</label>
                <div class="profile_info_wrap" v-if="isSocialLogin">
                    <img
                        v-if="userinfo.comeFrom === 'naver'"
                        class="profile_info_social_img"
                        src="/media/img/visitor/icon_cmt_naver.svg"
                    />
                    <img
                        v-if="userinfo.comeFrom === 'kakao'"
                        class="profile_info_social_img"
                        src="/media/img/visitor/icon_cmt_kakao.svg"
                    />
                    <input class="w_100 profile_info_social_input" type="text" disabled :value="userinfo.email" />
                </div>
                <div class="profile_info_wrap" v-else>
                    <img
                        v-if="userinfo.comeFrom === 'email'"
                        class="profile_info_social_img email"
                        src="/media/img/visitor/icon_cmt_email.png"
                    />
                    <img
                        v-if="userinfo.comeFrom === 'apple'"
                        class="profile_info_social_img"
                        src="/media/img/visitor/icon_cmt_apple.png"
                    />

                    <input
                        class="w_100 profile_info_social_input"
                        :class="{ email: userinfo.comeFrom === 'email' }"
                        type="text"
                        disabled
                        :value="userinfo.email"
                    />
                    <button class="w_100 profile_info_pw_button" @click="$emit('changepassword')">비밀번호 변경</button>
                </div>
            </li>
            <li class="flex_column profile_info_item">
                <label class="profile_info_label">이름</label>
                <div class="profile_info_wrap">
                    <input class="w_100 profile_info_input disabled" type="text" disabled :value="userinfo.name" />
                </div>
            </li>
            <li class="flex_column profile_info_item">
                <label class="profile_info_label">생년월일 </label>
                <div class="profile_info_wrap">
                    <input class="w_100 profile_info_input disabled" type="text" disabled :value="userinfo.birthDate" />
                </div>
            </li>
            <li class="flex_column profile_info_item">
                <label class="profile_info_label">휴대폰 번호</label>
                <div class="profile_info_wrap">
                    <input class="w_100 profile_info_input disabled" type="text" disabled :value="userinfo.mobileNo" />
                </div>
            </li>
            <li class="flex_column profile_info_item">
                <a class="profile_info_item_withdrawl" @click="$emit('withdrawl')">회원탈퇴</a>
            </li>
        </ul>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProfileInfoMo',
  emits: ['changepassword', 'withdrawl'],
  props: {
    userinfo: {
      type: Object,
      required: false,
      default: () => {
        return {
          birthDate: '',
          comeFrom: '',
          email: '',
          mobileNo: '',
          name: ''
        };
      }
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {},
  computed: {
    isSocialLogin () {
      const comeFrom = this.userinfo.comeFrom;
      if (comeFrom === 'kakao' || comeFrom === 'naver') {
        return true;
      }
      return false;
    }
  }
});
</script>
<style scoped src="@/assets/css/profile/profileinfomo.css"></style>
