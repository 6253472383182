<template>
    <div class="mo-profile">
        <div class="profile_header">
            <div class="profile_header-close">
                <img src="/media/images/icon_back_24.svg" alt="" @click="closePopup">
            </div>

            <h2 class="txt-white">알림 설정</h2>

            <div></div>
        </div>

        <div class="profile_title">
            <p>마케팅 수신 동의</p>
        </div>
        <div class="profile_content">
            <div class="content-item">
                <p>메일</p>
                <div class="alarm_btn_box">
                    <div class="email_alarm">
                        <div class="alarm_box alarm_box_check1" @click="changeMail()">
                            <span :class="mailStatus ? 'active1' : ''"></span>
                            <span class="move" :class="mailStatus ? 'active1_1' : ''"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-item">
                <p>SMS</p>
                <div class="alarm_btn_box">
                    <div class="email_alarm">
                        <div class="alarm_box alarm_box_check2" @click="changeSms()">
                            <span :class="smsStatus ? 'active1' : ''"></span>
                            <span class="move" :class="smsStatus ? 'active1_1' : ''"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-item">
                <p>사이트 알림함</p>
                <div class="alarm_btn_box">
                    <div class="email_alarm">
                        <div class="alarm_box alarm_box_check3" @click="changeSite()">
                            <span :class="notiStatus ? 'active1' : ''"></span>
                            <span class="move" :class="notiStatus ? 'active1_1' : ''"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <OnModal v-if="onMessageStatus" @closeOnModal="onMessageStatus = false"></OnModal>
        <OffModal v-if="offMessageStatus" @closeOffModal="offMessageStatus = false"></OffModal>
    </div>
</template>

<script>
import OnModal from '@/components/modal/Profile/PushOnModal.vue';
import OffModal from '@/components/modal/Profile/PushOffModal.vue';
import VueCookies from "vue-cookies";
import axios from 'axios';

export default {
  name: 'moPush',

  components: {
    OnModal,
    OffModal
  },

  data () {
    return {
      mailStatus: false,
      smsStatus: false,
      notiStatus: false,

      onMessageStatus: false,
      offMessageStatus: false
    };
  },

  created () {
    this.getPush();
  },

  methods: {
    // 상태값 불러오기
    getPush () {
      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/user/getNotiSet", { userId }, { headers })
        .then(res => {
          this.notiInfo = res.data.result;
          if (this.notiInfo.mailNoti === 'Y') { // 메일
            this.mailStatus = true;
            // this.mailStart();
          }
          if (this.notiInfo.snsNoti === 'Y') { // SNS
            this.smsStatus = true;
            // this.smsStart();
          }
          if (this.notiInfo.notiBox === 'Y') { // 알람
            this.notiStatus = true;
            // this.notiStart();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    // 상태값 변경
    changeMail () {
      let openStatus = false;
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        openStatus = true;
      }

      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/user/getMailNoti', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.mailStatus = !this.mailStatus;
            this.getPush();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (openStatus) {
            this.onMessagePopup();
          } else {
            this.offMessagePopup();
          }
        });
    },

    changeSms () {
      let openStatus = false;
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        openStatus = true;
      }

      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/user/getSnsNoti', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.smsStatus = !this.smsStatus;
            this.getPush();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (openStatus) {
            this.onMessagePopup();
          } else {
            this.offMessagePopup();
          }
        });
    },

    changeSite () {
      let openStatus = false;
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        openStatus = true;
      }

      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/user/getNotiBox', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.notiStatus = !this.notiStatus;
            this.getPush();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (openStatus) {
            this.onMessagePopup();
          } else {
            this.offMessagePopup();
          }
        });
    },

    offMessagePopup () {
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        this.offMessageStatus = true;
      }
    },

    onMessagePopup () {
      this.onMessageStatus = true;
    },

    // 알림 설정 닫기
    closePopup () {
      this.$emit('closeMoPopup');
    }
  }
};
</script>

<style scoped>
  .mo-profile {
    display: none;
  }

  .txt-white {
    color: #fff;
  }

  @media all and (max-width: 500px) {
    .mo-profile {
      display: block;

      position: fixed;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background: #151515;
    }

    .profile_header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 13px;
      margin-bottom: 22px;
      padding: 13px 5%;
      border-bottom: 0px solid hsla(0,0%,100%,.08);
    }

    .profile_header-close {
      position: absolute;
      left: 5%;
    }

    .profile_header-close img {
      width: 21px;
    }

    .profile_header h2 {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .profile_title {
      width: 100%;
      padding: 0 5%;
      margin-bottom: 24px;
    }

    .profile_title p {
      color: hsla(0,0%,100%,.87);
      font-size: 16px;
      font-weight: 400;
    }

    .profile_content {
      width: 100%;
    }

    .content-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5%;
      margin-bottom: 20px;
    }

    .content-item p {
      color: hsla(0,0%,100%,.6);
      font-size: 16px;
      font-weight: 300;
      white-space: nowrap;
    }

    .content-item:last-child {
      margin: 0;
      padding-bottom: 30px;
      border-bottom: 1px solid hsla(0,0%,100%,.08);
    }
  }
</style>
