<template>
    <div class="authphohe_background">
        <div class="authphone_modal">
            <img class="authphone_close cursor" src="/media/img/profile/icon_close.svg" alt="종료" @click="closeModal" />
            <h1 class="authphone_title">휴대폰 번호 확인</h1>
            <p class="authphone_desc">
                개인정보 보호를 위해 본인 인증시 사용된 <br />
                휴대폰 번호를 입력해주세요.
            </p>
            <div class="flex_column">
                <input
                    @keyup.enter="getUserInfo"
                    v-model="mobileNo"
                    type="text"
                    class="authphone_input"
                    placeholder="‘-’를 제외한 휴대폰 번호"
                />
                <button :disabled="isDisabled" class="authphone_button w_100" @click="getUserInfo">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import { getUser } from '@/service/api/userApi';
export default {
  name: 'AuthPhone',
  emits: ['close-authphone', 'getuserinfo'],

  data () {
    return {
      mobileNo: ''
    };
  },

  mounted () {},

  methods: {
    closeModal () {
      this.$emit('close-authphone');
    },
    getUserInfo () {
      const userId = this.$cookies.get('userId');
      const mobileNo = this.mobileNo;

      getUser(userId, mobileNo)
        .then(res => {
          const { data } = res;
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            this.$emit('getuserinfo', result);
          } else {
            alert('휴대폰 번호 확인에 실패하였습니다.');
          }
        })
        .catch(err => {
          if (err.response) {
            const { resultMsg } = err.response.data;
            alert(resultMsg);
          } else {
            alert('휴대폰 번호 확인에 실패하였습니다.');
          }
        });
    }
  },
  computed: {
    isDisabled () {
      if (this.mobileNo) {
        return false;
      }

      return true;
    }
  }
};
</script>
<style scoped src="@/assets/css/profile/authphone.css"></style>
