<template>
    <div class="changepassword_wrap">
        <input
            class="w_100 changepassword_item"
            type="password"
            v-model="password"
            @keyup="checkLength"
            placeholder="현재 비밀번호"
        />
        <input
            class="w_100 changepassword_item"
            type="password"
            placeholder="새 비밀번호 (영문, 숫자, 특수문자 포함 8-20자)"
            v-model="newPassword"
            @keyup="checkPassword"
        />
        <input
            class="w_100 changepassword_item"
            type="password"
            placeholder="새 비밀번호"
            @keyup="matchPassword"
            v-model="reInputPassword"
        />
        <p class="error_message" v-if="alertMessage">{{ alertMessage }}</p>
        <div class="changepassword_btn_wrap">
            <button class="changepassword_btn" @click="changePassWord" :disabled="isDisabled">다음</button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ChangePassWord',

  data () {
    return {
      alertMessage: '',
      password: '',
      newPassword: '',
      reInputPassword: ''
    };
  },
  emits: ['change-pass'],
  mounted () {},

  methods: {
    changePassWord () {
      const sendPassword = {
        password: btoa(this.password),
        newPass: btoa(this.newPassword)
      };
      this.$emit('change-pass', sendPassword);
    },
    checkLength () {
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage = '';
      } else {
        this.alertMessage = '비밀번호를 입력해주세요.';
      }
    },
    checkPassword () {
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.newPassword)
        ? (this.alertMessage = '')
        : (this.alertMessage = '비밀번호를 규칙에 맞게 입력해주세요.');

      if (this.newPassword.length === 0) {
        this.alertMessage = '';
      }
      if (this.newPassword === this.reInputPassword) {
        this.alertMessage = '';
      }
      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage = '';
      }
    },
    matchPassword () {
      if (this.newPassword !== this.reInputPassword) {
        this.alertMessage = '비밀번호가 일치하지 않습니다.';

        return false;
      }

      if (this.password.length > 0 && this.newPassword.length > 0 && this.reInputPassword.length > 0) {
        this.alertMessage = '';
      } else {
        this.alertMessage = '비밀번호를 입력해주세요.';
      }
    }
  },
  computed: {
    isDisabled () {
      if (this.password && this.newPassword && this.reInputPassword && this.alertMessage === '') {
        return false;
      }

      return true;
    }
  }
};
</script>
<style scoped src="@/assets/css/profile/changepasswordmo.css"></style>
