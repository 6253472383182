<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                전송자 : 어플레이즈<br>
                이메일 수신동의 일시 : {{ year }}년 {{ month }}월 {{ day }}일 {{ hours }}시<br>
                처리내용 : 수신동의 처리 완료<br>
                (설정 > 알림 설정에서 변경 가능)
            </h3>
            <div class="single-file-check">
                <p @click="$emit('closeOnModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'pushOnModal',
  data () {
    return {
      year: '',
      month: '',
      day: '',
      hours: ''
    };
  },
  methods: {
    getTime () {
      var today = new Date();
      this.year = today.getFullYear();
      this.month = ('0' + (today.getMonth() + 1)).slice(-2);
      this.day = ('0' + today.getDate()).slice(-2);
      this.hours = ('0' + today.getHours()).slice(-2);
    }
  },
  created () {
    this.getTime();
  }
};
</script>
<style scoped>
  .profile_popup .popup_box p {
    text-align: center;
  }
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
