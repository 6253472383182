import axios from 'axios';

/**
 *
 * @param {String} spaceId
 * @param {String} noticeTypeDetail
 * @param {String} headers
 * @returns
 */
const updateCurationNotice = async (spaceId, noticeTypeDetail, headers) => {
  return await axios.post('/api/menu/updateCurationNotice', { spaceId, noticeTypeDetail }, { headers });
};

/**
 *
 * @param {String} userId
 * @returns Promise
 */
const getAttendenceInfo = async userId => {
  return await axios.post(`/api/menu/getAttendanceInfo`, { userId });
};

/**
 *
 * @param {Number} limit
 * @param {Number} offset
 * @returns Promise
 */
const getEventList = async (limit, offset) => {
  return await axios.post('/api/menu/getEventList', { limit, offset });
};

/**
 *
 * @param {*} val (partnerCompany, partnerNm, partnerEmail, partnerContent, partnerPhone, partnerCategory  )
 * @returns
 */
const createPartnership = async val => {
  return await axios.post('/api/menu/createPartnership', val);
};
export { getAttendenceInfo, getEventList, updateCurationNotice, createPartnership };
