<template>
    <div class="alarm-area">
        <div class="pc-profile">
            <div class="alarm_setting_top">
                <p>마케팅 수신 동의</p>
            </div>
            <div class="alarm_setting">
                <p>메일</p>
                <div class="alarm_btn_box">
                    <div class="email_alarm">
                        <div class="alarm_box alarm_box_check1" @click="changeMail()">
                            <span :class="mailStatus ? 'active1' : ''"></span>
                            <span class="move" :class="mailStatus ? 'active1_1' : ''"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alarm_setting">
                <p>SMS</p>
                <div class="alarm_btn_box">
                    <div class="email_alarm">
                        <div class="alarm_box alarm_box_check2" @click="changeSms()">
                            <span :class="smsStatus ? 'active1' : ''"></span>
                            <span class="move" :class="smsStatus ? 'active1_1' : ''"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alarm_setting">
                <p>사이트 알림함</p>
                <div class="alarm_btn_box">
                    <div class="email_alarm">
                        <div class="alarm_box alarm_box_check3" @click="changeSite()">
                            <span :class="notiStatus ? 'active1' : ''"></span>
                            <span class="move" :class="notiStatus ? 'active1_1' : ''"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <OnModal v-if="onMessageStatus" @closeOnModal="onMessageStatus = false"></OnModal>
        <OffModal v-if="offMessageStatus" @closeOffModal="offMessageStatus = false"></OffModal>
    </div>
</template>

<script>
import OnModal from '@/components/modal/Profile/PushOnModal.vue';
import OffModal from '@/components/modal/Profile/PushOffModal.vue';
import VueCookies from "vue-cookies";
import axios from 'axios';

export default {
  name: 'pcPush',

  components: {
    OnModal,
    OffModal
  },

  data () {
    return {
      mailStatus: false,
      smsStatus: false,
      notiStatus: false,

      onMessageStatus: false,
      offMessageStatus: false
    };
  },

  created () {
    this.getPush();
  },

  methods: {
    // 상태값 불러오기
    getPush () {
      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/user/getNotiSet", { userId }, { headers })
        .then(res => {
          this.notiInfo = res.data.result;
          if (this.notiInfo.mailNoti === 'Y') { // 메일
            this.mailStatus = true;
            // this.mailStart();
          }
          if (this.notiInfo.snsNoti === 'Y') { // SNS
            this.smsStatus = true;
            // this.smsStart();
          }
          if (this.notiInfo.notiBox === 'Y') { // 알람
            this.notiStatus = true;
            // this.notiStart();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    // 상태값 변경
    changeMail () {
      let openStatus = false;
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        openStatus = true;
      }

      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/user/getMailNoti', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.mailStatus = !this.mailStatus;
            this.getPush();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (openStatus) {
            this.onMessagePopup();
          } else {
            this.offMessagePopup();
          }
        });
    },

    changeSms () {
      let openStatus = false;
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        openStatus = true;
      }

      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/user/getSnsNoti', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.smsStatus = !this.smsStatus;
            this.getPush();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (openStatus) {
            this.onMessagePopup();
          } else {
            this.offMessagePopup();
          }
        });
    },

    changeSite () {
      let openStatus = false;
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        openStatus = true;
      }

      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/user/getNotiBox', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.notiStatus = !this.notiStatus;
            this.getPush();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (openStatus) {
            this.onMessagePopup();
          } else {
            this.offMessagePopup();
          }
        });
    },

    offMessagePopup () {
      if (!this.mailStatus && !this.smsStatus && !this.notiStatus) {
        this.offMessageStatus = true;
      }
    },

    onMessagePopup () {
      this.onMessageStatus = true;
    },

    // 알림 설정 닫기
    closePopup () {
      this.$emit('closeMoPopup');
    }
  }
};
</script>

<style scoped>
  .pc-profile {
    display: block;
  }

  @media all and (max-width: 500px) {
    .pc-profile {
      display: none;
    }
  }
</style>
