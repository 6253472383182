<template>
    <div class="withdrawal_container">
        <div class="withdrawal_modal mo_confirm">
            <div class="head">
                <h1 class="title">
                    회원탈퇴
                </h1>
                <svg @click="$emit('closeWithdrawalConfirmModal')" class="close_btn" width="20" height="20" viewBox="0 0 19 19"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                        fill="white" />
                </svg>
            </div>
            <div class="mo_head">
                <h1 class="title mo_title">
                    회원탈퇴
                </h1>
            </div>
            <div class="body">
                <ul class="withdrawal_list">
                    <li class="withdrawal_item">
                        <img src="/media/img/non_background_check.png" alt="non_background_check.png">
                        <span class="text">회원님의 모든 정보가 삭제됩니다.</span>
                    </li>
                    <li class="withdrawal_item">
                        <img src="/media/img/non_background_check.png" alt="non_background_check.png">
                        <span class="text">탈퇴 후 14일간 재가입이 불가능합니다.</span>
                    </li>
                    <li class="withdrawal_item">
                        <img src="/media/img/non_background_check.png" alt="non_background_check.png">
                        <span class="text">구독중인 서비스는 모두 해지되며, 남은 기간은 유효합니다.</span>
                    </li>
                </ul>
            </div>
            <div class="footer">
                <button class="footer_btn cancel_btn" @click="$emit('closeWithdrawalConfirmModal')">취소</button>
                <button class="footer_btn cofirm_btn" @click="$emit('confirmWithdrawalmodal')">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'WithdrawalConfirm',
  emits: ['closeWithdrawalConfirmModal', 'confirmWithdrawalmodal'],
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  }
};
</script>
<style scoped>
.close_btn {
  cursor: pointer;
}

.withdrawal_modal>.body {
  padding: 25px 24px 65px 24px;
}

.withdrawal_modal>.body>.withdrawal_list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.withdrawal_item {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}

.withdrawal_item>img {
  width: 18px;
  height: 18px;
  cursor: default;
  margin-right: 12px;
}

.withdrawal_item>.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.87);
  cursor: default;
  word-break: keep-all;
}

.footer_btn {
  padding: 8px 30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 30px;
}

.cancel_btn {
  background-color: rgba(21, 21, 21, 0);
}

.cofirm_btn {
  background-color: var(--secondary);
}

.mo_head {
  display: none;
}

@media all and (max-width: 500px) {
  .mo_confirm {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 284px;
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  }

  .mo_head {
    display: flex;
  }

  .withdrawal_modal>.mo_head {
    margin-top: 0;
    padding: 0;
    padding-top: 32px;
  }

  .withdrawal_modal>.mo_head>.mo_title {
    width: 100%;
  }

  .withdrawal_modal>.body {
    padding: 10px 12px 25px 24px;
  }

  .withdrawal_item>img {
    margin-right: 4px;
    height: 20px;
  }

  .withdrawal_item>.text {
    line-height: 20px;
  }

  .withdrawal_modal .footer {
    padding: 0;
    justify-content: flex-start;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
  }

  .withdrawal_modal .footer_btn {
    width: 50%;
    padding: 16px 50px;
  }

  .cancel_btn {
    color: rgba(255, 255, 255, 0.87);
  }

  .cofirm_btn {
    color: var(--secondary);
    background: transparent;
  }

}
</style>
