<template>
    <div class="withdrawal_container">
        <div class="withdrawal_modal mo_confirm">
            <div class="body">
                <p class="desc">
                    지금까지 어플레이즈를 <br>
                    이용해주셔서 감사합니다.
                </p>
            </div>
            <div class="footer">
                <button class="confirm" @click="$emit('endwithdrawal')">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'WithdrawalEnd',
  emits: ['endwithdrawal'],
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  }
};
</script>
<style scoped>
.withdrawal_modal {
  width: 280px;
}

.withdrawal_modal>.body {
  padding: 32px 32px 24px 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.withdrawal_modal>.body>.desc {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.withdrawal_modal .footer {
  padding: 10px 38px;
  justify-content: center
}

.withdrawal_modal .footer .confirm {
  color: var(--secondary);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 80px;
}

@media all and (max-width: 500px) {
  .mo_confirm {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 284px;
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  }
}
</style>
