<template>
    <div class="wrap">
        <WithdrawalCheck v-if="ShowWithdrawal" @closeWithdrawalCheck="onCloseWithdrawalCheck" />
        <div class="profile_getout_modal modal">
            <div class="profile_getout_box modal_box">
                <div class="main_box_title modal_title">
                    <h1>회원탈퇴</h1>
                    <div @click="$emit('closewithdrawalmodal')">
                        <a href="#">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="mo_space_header">
                    <div class="mo_header_box">
                        <a href="#" @click="$emit('closeWithdrawal')">
                            <img src="/media/images/icon_back_24.svg">
                        </a>
                        <h2>회원탈퇴</h2>
                        <div></div>
                    </div>
                </div>
                <div class="cs_main">
                    <div class="cs_content profile_content">
                        <div class="policy_title">
                            <h3>
                                지금까지 어플레이즈를 이용해주셔서 감사합니다.<br>
                                회원 탈퇴를 위해 아래 사항을 확인해주시기 바랍니다.
                            </h3>
                            <div class="carful_notice">
                                <ul>
                                    <li><img src="/media/img/get_out_check.svg"></li>
                                    <li>회원님의 모든 정보가 삭제됩니다.</li>
                                </ul>
                                <ul>
                                    <li><img src="/media/img/get_out_check.svg"></li>
                                    <li>탈퇴 후 14일간 재가입이 불가능합니다.</li>
                                </ul>
                                <ul>
                                    <li><img src="/media/img/get_out_check.svg"></li>
                                    <li>구독중인 서비스는 모두 해지됩니다.</li>
                                </ul>
                            </div>
                            <div class="carful_notice_bottom">
                                <div class="check_box">
                                    <checkboxWithdrwal v-model="agreeWithdrawal" style="cursor: pointer;" />
                                    <p class="cursor" @click="agreeWithdrawal = !agreeWithdrawal">위 사항을 모두 확인했고 회원 탈퇴에 동의합니다.</p>
                                </div>
                                <div class="btn_area">
                                    <button id="prev_btn" @click="$emit('closewithdrawalmodal')">취소</button>
                                    <button id="get_out_btn" @click="clickWithdrawal()">확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_bottom">
                    <button @click="clickWithdrawal()">확인</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import checkboxWithdrwal from "../../../components/checkbox/checkboxWithdrwal.vue";
import WithdrawalCheck from "@/components/modal/Profile/WithdrawalCheck.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'withdrawal',
  emits: ["closewithdrawalmodal", "agreewithdrawalmodal"],
  data () {
    return {
      ShowWithdrawal: false,
      agreeWithdrawal: false
    };
  },
  components: {
    WithdrawalCheck,
    checkboxWithdrwal
  },
  methods: {
    clickWithdrawal () {
      if (this.agreeWithdrawal) {
        this.ShowWithdrawal = true;
      } else {
        alert("회원탈퇴에 동의해주세요.");
      }
    },
    onCloseWithdrawalCheck (val) {
      this.ShowWithdrawal = false;
      if (val === 'Y') {
        this.$emit("agreewithdrawalmodal");
      }
    }
  }
});
</script>
<style scoped>
.profile_getout_modal {
  display: block;
  z-index: 9995;
}

@media all and (min-width: 501px) {
  .mo_space_header {
    display: none;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
